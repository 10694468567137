const $privateApi = useNuxtApp().$privateApi;
const config = useRuntimeConfig();
const host = config.public.baseURL


export const _api = {
  module: {
    index: '/module/',
  },
  actions: {
    index: 'action',
  },
  user: {
    index: 'api/bo/user',
    create: '/api/bo/user/new',
    update: 'api/bo/user',
    delete: '/api/bo/user/',
    userByid: '/api/bo/user/',
    userNotActive: 'api/bo/user/notActive?',
    status:'status-customer',
    changecentre:'api/bo/auth/changecentre/'
  },
  role: {
    index: 'role',
    create: 'role',
    sanspopulate: '/role/sanspopulate',
    delete: 'role/'
  },
  function: {
    index: '/api/bo/fonction',

  },
  affectationCentre: {
    centre: 'usercentre/',
  },
  centre: '/centre',
  services: '/services',
  menu:'/menu/'
}  